import {graphql} from "gatsby"
import React from "react"
import Layout from "../Page"
import BlogItems from "../Includes/BlogItems"
import config from "../../config/config"

const BlogHolder = ({ data: { silverStripeDataObject }}) => {
    const { bannerTitle, bannerSubTitle, bannerImageURL, metaTags, customCSS} = silverStripeDataObject.Page
    const parentURL = silverStripeDataObject.SilverStripeSiteTree.urlSegment
    const pageData = {
        pageClass: 'BlogHolder', 
        bodyClass: '', 
        pageTitle: '',
        metaTags: metaTags,      
        customCSS: customCSS,
    }
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    var _keyword = ''
    if(params.get("keyword")){
        _keyword = params.get("keyword").toLowerCase()
    }
    var inlineStyle = {}
    if(bannerImageURL){
        inlineStyle.backgroundImage = `url(${bannerImageURL})`
    }
    return (
        <Layout pageData={pageData}>
            {(bannerTitle || bannerImageURL) && (
            <header
                className='title-wrap'
                style={inlineStyle}
            >
            {bannerTitle && (<h3>{bannerTitle}</h3>)}
            {bannerSubTitle && (<h1>{bannerSubTitle}</h1>)}
            </header>
            )}
            <section className="section">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col col-lg-10 mb-4 text-center">
                      <h1>BLOG</h1>
                    </div>
                  </div>
                  <div className="explore-functional row justify-content-center">
                    <div className="col-xl-6 col-lg-10">
                      <form className="search-box d-flex mb-5">
                        <input
                          name="keyword"
                          className="filter-text"
                          placeholder="SEARCH"
                          defaultValue={_keyword}
                        />
                        <div className="filter-icon">
                          <img src="/img/explore/search.svg" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <BlogItems parentURL={parentURL}/>
              </section>
        </Layout>
    )
}

export default BlogHolder
export const pageQuery = graphql`
    query ($link: String!) {
            silverStripeDataObject(link: { eq: $link }) {
                SilverStripeSiteTree {
                    urlSegment
                }
                Page{
                    bannerTitle
                    bannerSubTitle
                    bannerImageURL
                    metaTags
                    customCSS
                }
            }

    }
`